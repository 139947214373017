<template>
  <Toolbar v-if="car" class="p-mb-4 p-mt-4">
<!--    <template #start>-->
<!--      <AddNewButton :disabled="!allDataLoaded" :label="$t('Add')" @click="addNewItem()" />-->
<!--    </template>-->
    <template #end>
      <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
    </template>
  </Toolbar>
  <Spinner v-if="isLoading"></Spinner>
  <DesktopDataTable v-else
                    class="p-mt-4"
                    tableName="Car goods"
                    :dataTableIsLoading="dataTableIsLoading"
                    :itemsArrayIsEmpty="itemsArrayIsEmpty"
                    :sort="sort"
                    :showSearchInput="false"
                    :showButtons="false"
                    @sort-items="sortItems"
                    :headers="[
                      {name: 'type', title: '',  width: '30px', sortable: false},
                      {name: 'supplier', title: 'Supplier',  width: '', sortable: true},
                      {name: 'code', title: 'Code',  width: '', sortable: true},
                      {name: 'name', title: 'Name',  width: '', sortable: true},
                      {name: 'purchase_price', title: 'Purchase price',  width: '', sortable: true},
                      {name: 'sell_price', title: 'Sell price',  width: '', sortable: true},
                      {name: 'order', title: 'Order',  width: '', sortable: false},
                      {name: 'created_at', title: $t('Created'),  width: '', sortable: true}]">
    <template v-slot:body>
      <tr v-for="(item, index) of items" :key="index">
        <td class="table-data">
          <i :class="{'ti-hummer':item.type === 1,
                    'ti-truck':item.type === 2,
                    'ti-shopping-cart':item.type === 3,
                    'ti-pencil':item.type === 4}">
          </i>
        </td>
        <td class="table-data">{{ item.supplier?.company_name }}</td>
        <td class="table-data">{{ item.code }}</td>
        <td class="table-data">{{ item.name }}</td>
        <td class="table-data">
          <span v-if="item.purchase_price">{{ formatCurrency(+item.purchase_price) }}</span>
        </td>
        <td class="table-data">
          <span v-if="item.sell_price">{{ formatCurrency(+item.sell_price) }}</span>
        </td>
        <td>
          <span v-if="item.order">
            <router-link :to="{ path: `/orders/${item.order.number}` }" target="_blank">{{ item.order.number }}</router-link>
          </span>
        </td>
        <td class="table-data table-date-line">
          <span v-if="item.created_at">{{ formatDate(item.created_at) || '' }}, </span>
          <span v-if="item.creator">
            <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}<span> ({{ item.creator.first_name }} {{ item.creator.last_name }})</span></span>
            <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>
          </span>
        </td>
      </tr>
    </template>
  </DesktopDataTable>

  <OwnPaginator v-show="items && items.length"
                :rows="pagination.perPage"
                :currentPage="pagination.currentPage - 1"
                :totalPages="pagination.pageCount"
                :totalRecords="pagination.totalCount"
                :rowsPerPageOptions="[5,10,25,50,100,200]"
                :pageLinkSize="11">
  </OwnPaginator>

  <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>
</template>

<script>
import httpClient from '@/services/http.services'
import httpMixins from "@/mixins/httpMixins";
import formatMixins from '@/mixins/formatMixins'
import datatableMixins from '@/mixins/datatableMixins'

export default {
  mixins: [ formatMixins, datatableMixins, httpMixins ],
  emits: ['update-items', 'change-customer-status'],
  props: {
    car: Object,
  },
	data() {
		return {
      fieldsExpand: 'order',
      apiLink: 'car/get-goods',
      // sort: {
      //   column: 'id',
      //   direction: '-',
      // },
		}
	},
  watch: {
    '$route'() {
      const route = this.$route.path.split('/')
      if (this.$route.path === '/login' || route.indexOf('cars') === -1 || route.indexOf('goods') === -1) {
        return false
      }
      this.checkRouteQuery()
      this.getItems()
    },
  },
  mounted() {
    if (this.$route.params.id ) {
      this.checkRouteQuery()
      this.getItems()
    }
  },
  methods: {
    async getItems() {
      this.dataTableIsLoading = true
      try {
        // const { status, data, headers } = await httpClient.post(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`, { carId: this.$route.params.id })
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          method: 'post',
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
          data: {
            carId: this.$route.params.id
          }
        })

        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (!data.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.disableEditButton = false
        this.dataTableIsLoading = false
      }
    },
	},
  computed: {
    // itemsArrayIsEmpty() {
    //   return !(this.items && this.items.length)
    // },
    // actionButtonsCount() {
    //   return 0
    // },
  }
}
</script>
<style scoped>
.table-data {
  height: 45px;
}
</style>